.animeAlarmsBanner {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  background-size: cover;
  background-image: url('/images/banners/anime_banner/anime-banner-bg-1.jpg');
  .lastImage {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    .image {
      height: 100%;
      content: url('/images/banners/anime_banner/anime-banner-characters-1.png');
    }
  }
  .content {
    margin-left: 10px;
    .text {
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      color: #FFE500;
      // -webkit-text-stroke-width: 0.8px;
      // -webkit-text-stroke-color: #ffffff;
      margin-right: 3px;
    }
    .smallText {
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 700;
      display: inline-flex;
      font-size: 20px;
      color: #fff;
      // -webkit-text-stroke-width: 0.8px;
      // -webkit-text-stroke-color: #ffffff;    
      align-items: center;
    }
  }
  &.purple {
    background-image: url('/images/banners/anime_banner/anime-banner-bg-2.jpg');
    .lastImage .image {
      content: url('/images/banners/anime_banner/anime-banner-characters-2.png');
    }
    .text {
      color: #FFE500;
      // -webkit-text-stroke-color: #254929;
    }
    .smallText {
      color: #fff;
      // -webkit-text-stroke-color: #ffffff;    
    }
  }
  &.blue {
    background-image: url('/images/banners/anime_banner/anime-banner-bg-3.jpg');
    .lastImage .image {
      content: url('/images/banners/anime_banner/anime-banner-characters-3.png');
    }
    .content {
      .text {
        color: #FFE500;
        // -webkit-text-stroke-color: #621f37;
      }
      .smallText {
        color: #FAFAFA;
        // -webkit-text-stroke-color: #0e0e0e;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .animeAlarmsBanner {
    .content {
      display: flex;
      flex-direction: column;
      padding-top: 6px;
      .text {
        font-size: 18px;
        line-height: 18px;
        margin-right: 0px;
      }
      .smallText {
        margin-top: 1px;
        font-size: 15px;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .animeAlarmsBanner {
    .content {
      display: flex;
      flex-direction: column;
      padding-top: 3px;
      .text {
        font-size: 16px;
        line-height: 16px;
      }
      .smallText {
        margin-top: 1px;
        font-size: 14px;
        margin-left: auto;
      }
    }
  }
}
