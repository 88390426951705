.sectionWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 0 24px;
  .sectionTitleWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    .sectionTitle {
      display: flex;
      background: #fdfac5;
      color: #fff;
      width: 100%;
      justify-content: center;
      border-radius: 10px;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      padding: 1px;
    }
  }
}

@media screen and (max-width: 767px) {
  .sectionWrapper {
    padding: 0 8px;
  }
}
