.investmentLevelsTabSection {
  display: flex;
  flex-direction: column;
  .postsList {
    padding: 15px 24px !important;
  }
  .sectionHeader {
    display: flex;
    align-items: center;
    padding: 15px 24px 0 24px;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
  }
}

.investmentLevelsList {
  display: flex;
  align-items: center;
  margin-left: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .investmentLevel {
    font-size: 14px;
    line-height: 21px;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-weight: 400;
    color: var(--dark-gray-clr);
    background-color: var(--light-clr);
    border: 1px solid var(--dark-gray-clr);
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      font-weight: 600;
      color: var(--black-primary-1-clr);
      background-color: #e1f1e7;
    }
    &.active {
      background-color: var(--green-clr);
      color: var(--light-clr);
      border: 1px solid var(--green-clr);
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 767px) {
  .investmentLevelsTabSection {
    .postsList {
      padding: 15px 8px !important;
    }
    .sectionHeader {
      display: flex;
      align-items: center;
      padding: 10px 8px 0 8px;
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
      }
    }
  }
  .investmentLevelsList {
    .investmentLevel {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
