.postTopicsTabSection {
  display: flex;
  flex-direction: column;
  .createPost {
    padding: 20px 24px 10px 24px;
    border-bottom: 1px solid var(--light-gray-clr);
  }
  
  .postsTopicList {
    padding: 15px 24px !important;
  }  
}

// a list of topics, have move left and move right button
.topicsListWrapper {
  position: relative;
  &:hover {
    .backBtn, .nextBtn {
      display: flex;
    }
  }
}
.topicsList {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  padding: 12px 10px;
  border-bottom: 1px solid var(--light-gray-clr);
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .nextBtn, .backBtn {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: var(--light-gray-4-clr);
    z-index: 1000;
    display: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    top: 10px;
    border: 1px solid var(--medium-gray-4-clr);
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
  }
  .backBtn {
    left: 5px;
    svg {
      transform: rotate(-180deg);
    }      
  }
  .nextBtn {
    right: 5px;
  }
  .topic {
    font-size: 14px;
    line-height: 21px;
    padding: 3px 8px;
    flex-shrink: 0;
    font-weight: 400;
    color: var(--dark-gray-clr);
    background-color: var(--light-clr);
    border: 1px solid var(--dark-gray-clr);
    border-radius: 20px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      font-weight: 600;
      color: var(--black-primary-1-clr);
      background-color: #e1f1e7;
    }
    &.active {
      background-color: var(--green-clr);
      color: var(--light-clr);
      border: 1px solid var(--green-clr);
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 767px) {
  .postTopicsTabSection {
    .postsTopicList {
      padding: 10px 8px !important;
    }
  }
  .topicsListWrapper {
    .backBtn, .nextBtn {
      display: none !important;
    }
  }
}
