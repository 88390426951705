.createPostBox {
  display: flex;
  &.isProfilePage {
    padding: 15px 24px 10px 24px;
    border: 1px solid var(--gray-clr);
    border-radius: 12px;
    margin-bottom: 15px;
    .textInputWrapper {
      border: 1px solid var(--light-gray-clr);
    }
  }
  .avatar {
    margin-right: 15px;
  }
  .inputBox {
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    position: relative;
  }
  .textInputWrapper {
    font-size: 0;
    border-radius: 24px;
    border: 1px solid var(--gray-clr);
    margin-bottom: 9px;
    cursor: pointer;
    .textInput {
      border-radius: 24px;
      width: 100%;
      outline: none;
      padding: 10px 16px;
      font-size: 16px;
      background-color: var(--light-gray-1-clr);
      border: none;
      p {
        color: var(--dark-gray-clr);
      }
      &:hover {
        background: var(--light-gray-clr);
      }
    }
  }
  .footer {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    .iconWrapper {
      flex-grow: 1;
      max-width: 30%;
      cursor: pointer;
      border-radius: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 10px;
      white-space: nowrap;
      &:hover {
        background-color: var(--light-gray-clr);
      }
      .iconGroup {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        .icon {
          margin-right: 6px;
        }
      }
      .text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
