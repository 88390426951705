.noPost {
  padding-top: 35px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.post {
  display: flex;
  flex-direction: column;
}
