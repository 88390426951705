.container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: white;
  text-align: center;
  font-size: 12px;
  .arrow {
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: white;
    box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  }
}
