.coinBannerWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
  background: #0B9DD9;
  &.orange {
    background: #F06C22;
  }
  &.blue {
    background: #0B9DD9;
  }
  &.purple {
    background: #A120DE;
  }
  &.green {
    background: #09A800;
  }
  &.pink {
    background: #EF15BF;
  }
  &.orange1 {
    background: #F21918;
  }
  &.blue1 {
    background: #2853ED;
  }
  .content {
    margin: 0 auto;
    .text {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #FDE82B;
      margin-right: 5px;
    }
    .subText {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #fff;
    }
    .smallText {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 700;
      display: inline-flex;
      font-size: 10px;
      color: #fff;
      align-items: center;
      margin-left: 5px;
      span {
        display: inline-flex;
        margin-right: 4px;
      }
    }
  }
  .firstImage {
    margin-left: 15px;
  }

  .lastImage {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .coinBannerWrapper {
    .firstImage {
      margin-left: 10px;
    }
  
    .lastImage {
      margin-right: 10px;
    }
    .content {
      .text {
        font-size: 16px;
      }
      .subText {
        font-size: 14px;
      }  
    }
  }
}

@media screen and (max-width: 436px) {
  .coinBannerWrapper {
    .firstImage {
      margin-left: 8px;
    }
  
    .lastImage {
      margin-right: 8px;
    }
    .content {
      .text {
        font-size: 14px;
      }
      .subText {
        font-size: 12px;
      }  
    }
  }
}

@media screen and (max-width: 375px) {
  .coinBannerWrapper {
    .firstImage {
      margin-left: 6px;
    }
  
    .lastImage {
      margin-right: 6px;
    }
    .content {
      .text {
        font-size: 11px;
        color: #FDE82B;
      }
      .subText {
        font-size: 10px;
      }  
    }
  }
}
