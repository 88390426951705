.homeRecommendCreatorsList {
  * {
    font-family: var(--noto-font-family);  
  }
  padding: 15px 24px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--light-gray-clr);
  border-top: 1px solid var(--light-gray-clr);
  // title
  .title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  // creators list
  .creatorsList {
    width: 100%;
    // item with topicName and follow Btn
    .creatorItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #e5e5e5;
      }
      .followBtn {
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
        flex-shrink: 0;
        margin-left: 10px;
        cursor: pointer;
        border-radius: 36px;
        border: 1px solid var(--green-clr);
        padding: 4px 8px;
        color: var(--green-clr);
        background-color: var(--light-clr);
        &:hover {
          background-color: var(--green-clr);
          color: var(--light-clr);  
        }
      }
    }
  }
  .readMoreBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    button {
      width: fit-content;
      color: var(--green-clr);
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-decoration: none;
    }
  }
}

.creatorInfoWrapper {
  display: flex;
  .creatorAvatar {
    margin-right: 10px;
  }
  .creatorInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .userNameWrapper {
      display: flex;
      align-items: center;
      .userName {
        max-width: 100%;
        color: var(--black-clr);
        font-weight: 600;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        text-align: left;
      }
      .badge {
        flex-shrink: 0;
        margin-left: 12px;
        margin-right: 1px;    
      }
    }
    .userBio {
      margin-top: 6px;
      overflow: hidden;
      max-width: unset;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
      font-size: 14px;
      color: var(--black-primary-1-clr);
      line-height: 21px;
    }
  }
}

@media screen and (max-width: 767px) {
  .homeRecommendCreatorsList {
    padding: 10px 8px;
    .title {
      padding: 10px;
    }
  }
  .creatorInfoWrapper {
    .creatorInfo {
      .userBio {
        font-size: 13px;
        line-height: 24px;
      }
    }
  }  
}
