.messages {
  display: flex;
  width: 100%;
  padding: 0 24px 20px 24px;
  flex-direction: column;
  .message {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
    .close {
      position: absolute;
      top: 6.25px;
      right: 6.25px;
      cursor: pointer;
    }
    .content {
      font-size: 12px;
      line-height: 18px;
      white-space: pre-line;
      a {
        text-decoration: underline;
        display: inline-block;
        font-weight: 600;
        color: var(--green-clr);
        width: fit-content;
      }
    }
    &.info {
      border: 1px solid #ccc;
      background: #fff;
    }
    &.warn {
      border: 1px solid #FBF0DF;
      background: #FBF0DF;
      .content {
        color: #A7662A;
        font-weight: 600;
      };
    }

    &.error {
      border: 1px solid #FBDFE9;
      background: #FBDFE9;
      .content {
        color: #EA4C7B;
        font-weight: 600;
      };
    }
    // .acceptBtn {
    //   display: flex;
    //   margin-left: auto;
    //   font-size: 12px;
    //   line-height: 18px;
    //   border: 1px solid #EA4C7B;
    //   color: #EA4C7B;
    //   font-weight: 600;
    //   padding: 6px;
    //   border-radius: 4px;
    // }
  }
}

@media screen and (max-width: 767px) {
  .messages {
    padding: 0px 8px 15px 8px;
  }
}
