.container {
  padding: 15px 24px 50px 24px;
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .noPost {
    padding-top: 35px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: #666666;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .titleWrapper {
    display: flex;
    margin-bottom: 15px;
    .keyword {
      font-weight: 600;
      font-size: 20px;
      margin: unset;
    }
  }

  .sortSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    .linkBtn {
      line-height: 150%;
      cursor: pointer;
      font-size: 14px;
      line-height: 21px;
      padding: 3px 8px;
      border-radius: 23px;
      margin-left: 8px;
      flex-shrink: 0;
      background-color: var(--light-clr);
      color: var(--dark-gray-clr);
      border: 1px solid var(--dark-gray-clr);
      &.active {
        font-weight: 600;
        background-color: var(--green-clr);
        color: var(--light-clr);
        border: 1px solid var(--green-clr);;
      }
      &:not(.active):hover {
        opacity: 0.7;
      }
    }
  }
}

.post {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 10px 8px 50px 8px;
    .titleWrapper {
      display: flex;
      .keyword {
        font-size: 16px;
        line-height: 27px;
      }  
    }
  }
}
