.selectEmotionMenu {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 12px;
  font-size: 10px;
  color: #666666;
  .emotions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    .emotion {
      cursor: pointer;
      position: relative;
      &:not(:last-child) {
        margin-right: 8px;
      }
      &.active,
      &:hover {
        .highlight {
          box-shadow: 0px 0px 4px 2px rgba(4, 181, 106, 1);
          border-radius: 50%;
          position: absolute;
          top: 2px;
          bottom: 2px;
          left: 2px;
          right: 2px;
        }
      }
    }
  }
  .delEmotionBtn {
    cursor: pointer;
  }
}
