.timelinePage {
  .banner {
    padding: 0;
    margin-bottom: 25px;
  }
  .postsList {
    padding: 25px 24px 20px 24px;
  }
  .createPost {
    padding: 20px 24px 10px 24px;
    border-bottom: 1px solid var(--light-gray-clr);
  }
}

.noPost {
  padding-top: 35px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: #666666;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.tabsGroup {
  display: flex;
  flex-direction: column;
  .tabsHeader {
    position: sticky;
    z-index: 999;
    top: 64px;
    display: flex;
    align-items: center;
    background-color: var(--light-gray-1-clr);
    border-top: 1px solid var(--light-gray-clr);
    border-bottom: 1px solid var(--light-gray-clr);
    .tab {
      text-align: center;
      outline: 0;
      flex: 1;
      width: 25%;
      .tabName {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        height: 55px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        color: var(--dark-gray-clr);
        cursor: pointer;
        &:hover {
          color: var(--black-primary-1-clr);
          background-color: #e1f1e7;
          font-weight: 600;
        }
      }
      &.active {
        background-color: #D0FBE1;
        .tabName {
          color: var(--black-primary-1-clr);
          border-bottom: 4px solid var(--green-clr);
          font-weight: 600;
        }
      }
    }
  }
  .tabsContent {
    width: 100%;
    padding: 0;
  }
}

.liveIcon {
  margin-right: 5px;
  margin-bottom: 3px;
}

@media screen and (max-width: 767px) {
  .liveIcon {
    margin-right: 5px;
    margin-bottom: 2px;
  }
  .timelinePage {
    .postsList {
      padding: 20px 8px 10px 8px;
    }
    .banner {
      padding: 0;
      margin-bottom: 20px;
    }
  }
  .tabsGroup {
    .tabsHeader {
      .tab {
        flex-grow: 1;
        width: unset;
        .tabName {
          height: 50px;
          font-size: 14px;
          padding: 0 5px;
        }
      }
    }  
  }
}
